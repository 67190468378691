import React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { Grid } from '@material-ui/core'
import { s, colors, alpha } from '../style'
import appclever from '../assets/icons/appclever'
import { formatPhoneNumber } from '../utils'
import GooglePartner from '../assets/icons/GooglePartner.svg'
import RDBGroup from '../assets/icons/RDBGroup.svg'
import ReklamaDlaBiznesu from '../assets/icons/ReklamaDlaBiznesu.svg'

const { pages, brand } = require('../config')

const Footer = ({ theme, language, dispatch }) => {
  return (
    <footer css={sFooter}>
      <div css={sContainer1}>
        <Grid container>
          {Object.values(pages)
            .filter((page) => page.sections)
            .sort((a, b) => a.footerId - b.footerId)
            .map((page, id) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                css={[sFooterItem, sFooterItem.large]}
                key={id}
              >
                <div key={id}>
                  <Link to={page.slug} activeStyle={activeStylePage}>
                    <h5>{page.name[language]}</h5>
                  </Link>
                  {page.sections &&
                    page.sections.map((section, id) => (
                      <div
                        onClick={() => {
                          dispatch({ type: 'TOGGLE_MENU' })
                          navigate(`${page.slug}#${section.hash}`)
                        }}
                        key={id}
                      >
                        <h6>{section.footer && section.footer[language]}</h6>
                      </div>
                    ))}
                </div>
              </Grid>
            ))}
          <Grid item xs={12} md={6} lg={3}>
            {Object.values(pages)
              .filter((page) => page.name)
              .filter((page) => !page.sections)
              .filter((page) => page.footerId)
              .map((page, id) => (
                <div key={id} css={sFooterItem}>
                  <Link to={page.slug} activeStyle={activeStylePage}>
                    <h5>{page.name[language]}</h5>
                  </Link>
                </div>
              ))}
          </Grid>
        </Grid>
      </div>
      <div css={sLine} />
      <Grid container css={sContainer2}>
        <Grid item xs={12} md={6} lg={3} css={sContainer2.item}>
          <Link to="/" css={sLogo}>
            {appclever}
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={3} css={sContainer2.item}>
          <a href={brand.address.location}>
            {brand.address[language].map((line, id) => (
              <p key={id}>{line}</p>
            ))}
          </a>
        </Grid>
        <Grid item xs={12} md={6} lg={3} css={sContainer2.item}>
          <a href={`mailto:${brand.email}`}>{brand.email}</a>
        </Grid>
        <Grid item xs={12} md={6} lg={3} css={sContainer2.item}>
          <a href={`tel:${brand.phone}`}>{formatPhoneNumber(brand.phone)}</a>
        </Grid>
      </Grid>
      <div css={sLine} />
      <div css={sContainer3}>
        <p>{brand.partners[language]}</p>
        <Grid container justifyContent="space-evenly" css={sImagesContainer}>
          <Grid item xs={12} md={4} lg={4}>
            <a href="https://rdbgroup.pl" rel="noreferrer" target="_blank">
              <img src={RDBGroup} alt="RDB Group" />
            </a>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <a
              href="https://reklamadlabiznesu.pl"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={ReklamaDlaBiznesu}
                alt="Reklama Dla Biznesu"
                id="ReklamaDlaBiznesu"
              />
            </a>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <a
              href="https://www.google.com/intl/pl_pl/partners/about/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={GooglePartner}
                alt="Google Partner"
                id="GooglePartner"
              />
            </a>
          </Grid>
        </Grid>
      </div>
      <div css={sLine} />
      <div>
        <div css={sContainer4}>
          <Link to={pages.policy.slug} css={{ paddingLeft: '0.5rem' }}>
            {pages.policy.name[language]}
          </Link>
          <span css={{ paddingLeft: '0.5rem' }}>&bull;</span>
          <Link to={pages.download.slug} css={{ paddingLeft: '0.5rem' }}>
            {pages.download.name[language]}
          </Link>
        </div>
        <div
          css={[
            sContainer4,
            {
              [s.xs]: {
                flexDirection: 'row',
              },
            },
          ]}
        >
          Copyright &nbsp;
          <span css={{ color: colors.white.concat(alpha[100]) }}>
            © {new Date().getFullYear()}
          </span>
          &nbsp; {brand.name}
        </div>
      </div>
    </footer>
  )
}

const sFooter = {
  zIndex: 4000,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: `linear-gradient(${colors.background2.concat(
    alpha[100]
  )}, ${colors.background2.concat(alpha[100])})`,
  [s.sm_down]: {
    padding: '3rem',
    marginTop: '8rem',
  },
  [s.md]: {
    padding: '4rem',
    marginTop: '10rem',
  },
  h5: {
    [s.sm_down]: { fontSize: '1.25rem', paddingBottom: '1rem' },
    [s.md]: { fontSize: '1.25rem', paddingBottom: '1.25rem' },
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  h6: {
    cursor: 'pointer',
    [s.sm_down]: { fontSize: '0.875rem', paddingBottom: '0.5rem' },
    [s.md]: { fontSize: '0.875rem', paddingBottom: '0.75rem' },
    fontWeight: 300,
    opacity: 0.6,
    transition: 'opacity 0.2s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
  },
}

const sContainer1 = {
  display: 'flex',
  justifyContent: 'space-evenly',
  [s.sm_down]: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  [s.md]: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingBottom: '4rem',
  },
}

const sFooterItem = {
  [s.sm_down]: { paddingBottom: '2rem' },
  [s.md]: { paddingBottom: '0rem' },
  large: {
    [s.md]: { paddingBottom: '2rem' },
    [s.lg]: { paddingBottom: '0rem' },
  },
}

const sLine = {
  width: '100%',
  height: 2,
  backgroundColor: colors.white.concat(alpha[16]),
  margin: 0,
  borderRadius: 4,
}

const sLogo = {
  svg: {
    transition: 'opacity 0.2s ease-in-out',
    width: 128,
    height: 'auto',
    '&:hover': { opacity: 0.6 },
  },
}

const sContainer2 = {
  display: 'flex',
  justifyContent: 'space-between',
  [s.sm_down]: {
    flexDirection: 'column',
    padding: '3rem 0 2rem',
  },
  [s.md]: {
    flexDirection: 'row',
    padding: '4rem 0',
  },

  item: {
    transition: 'color 0.2s ease-in-out',
    cursor: 'pointer',
    color: colors.white.concat(alpha[60]),
    '&:hover': {
      color: colors.white.concat(alpha[100]),
    },
    [s.sm_down]: {
      fontSize: '0.875rem',
      paddingBottom: '1rem',
    },
    [s.md]: {
      fontSize: '0.875rem',
      paddingBottom: '2rem',
    },
    [s.lg]: { paddingBottom: 0 },
  },
}

const sContainer3 = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [s.sm_down]: { padding: '2rem 0 0' },
  [s.md]: { padding: '2rem 0 4rem' },

  p: {
    fontSize: '1rem',
    margin: '1rem 0 3rem',
    color: colors.white.concat(alpha[30]),
    textTransform: 'uppercase',
  },
}

const sContainer4 = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.75rem',
  color: colors.white.concat(alpha[30]),
  fontWeight: 700,
  [s.xs]: {
    flexDirection: 'column',
  },
  [s.sm_down]: { padding: '2rem 0 0' },
  [s.md]: { padding: '4rem 0 0' },
}

const sImagesContainer = {
  div: {
    [s.sm_down]: { marginBottom: '4rem', maxWidth: '256px' },
  },
  img: {
    [s.sm_down]: { maxHeight: '72px' },
    [s.md]: { maxHeight: '100px' },
  },
  '#GooglePartner': {
    [s.sm_down]: { filter: 'grayscale(0)', maxHeight: '64px' },
  },
  '#ReklamaDlaBiznesu': { [s.sm_down]: { maxHeight: '86px' } },
}

const activeStylePage = {
  opacity: 1,
  color: colors.yellow,
  textShadow: `0px 0px 16px ${colors.yellowOrange.concat(alpha[100])}`,
}

export default Footer
